.icons {
  color: $orange-5;
  font-size: 24px;
}

.settings-icon {
  color: $blue-6;
}

.drag-drop-icon {
  color: $blue-6;
  font-size: 48px;
}

.true-icon {
  color: $green-6;
}

.false-icon {
  color: grey;
}

.delete-icon {
  color: $red-6;
}
