#app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 70px 1fr 50px;
  gap: 5px;

  .app__main {
    justify-self: center;
    align-self: center;
  }
}
