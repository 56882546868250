@mixin flex($direction, $wrap, $justify, $align, $gap) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin implicit-grid($gap, $columns-number, $min-column-width, $row-height) {
  display: grid;
  gap: $gap;
  grid-template-columns: repeat($columns-number, minmax($min-column-width, 1fr));
  grid-auto-rows: $row-height;
}
