.work-force {
  @include flex(column, nowrap, space-between, center, 20px);

  .work-force__form {
    @include flex(row, wrap, space-between, center, 0);
  }

  .form__note-panel {
    width: 100%;
  }

  .work-force__table {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .work-force {
    @include flex(column, wrap, center, center, 0);
    width: auto;
    height: auto;

    .work-force__form {
      @include flex(row, wrap, space-around, center, 0);

      .form__project-controls {
        @include flex(row, nowrap, center, center, 0);
        width: 100%;
        text-align: center;
      }
    }

    .form__note-panel {
      width: 80%;
    }
  }
}
