.edit-project {
  @include flex(column, nowrap, space-around, center, 0);
  width: 700px;

  .edit-project__controls {
    @include flex(row, nowrap, center, center, 0);
  }

  .ant-form-item-label {
    text-align: left;
  }
}
