.dashboard {
  .dashboard__cards-container {
    @include flex(row, wrap, space-around, center, 5px);
    width: 900px;
    height: 500px;
    border: 2px dashed lightgray;
    border-radius: 5px;

    .cards-container__card {
      @include flex(column, nowrap, center, center, 0);
      border-style: hidden;
      border-radius: 5px;
      width: 250px;
      background-color: rgba($color: #000000, $alpha: 0);

      .card__cover {
        width: 200px;
        height: 200px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .dashboard {
    .dashboard__cards-container {
      width: auto;
      height: auto;
      border: none;
    }
  }
}
