.not-authorized {
  @include flex(column, nowrap, center, center, 0);

  img {
    width: 600px;
    height: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .not-authorized {
    img {
      width: 300px;
      height: 250px;
    }
  }
}
