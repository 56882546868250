.reports {
  @include flex(row, wrap, center, flex-start, 0);
  width: 100%;
  height: 100%;

  > div {
    margin-bottom: 20px;
  }

  .reports__controls {
    @include flex(row, wrap, center, center, 0);
    width: 100%;

    .reports__form {
      .form__radio-group {
        @include flex(column, nowrap, center, center, 0);
        margin-bottom: 5px;
      }
    }
  }

  .reports__report-details {
    width: 40%;
    height: 350px;
  }

  .reports__table {
    width: 49%;
    margin-right: 10px;
  }

  .reports__work-force-table {
    width: 49%;
  }

  .reports__work-force-pie-chart {
    @include flex(row, wrap, space-around, center, 0);
  }
}
