.add-project {
  @include flex(column, nowrap, center, center, 10px);

  .add-project__form {
    width: 700px;
  }

  .ant-form-item-label {
    text-align: left;
  }
}
