.header {
  @include flex(row, nowrap, space-between, center, 0);
  background-color: $blue-6;

  img {
    margin-left: 10px;
  }

  .header__nav {
    > button {
      font-size: 1em;
      font-weight: bold;
      color: white;
    }
  }
}
