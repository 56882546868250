.login {
  @include flex(row, wrap, center, center, 0);
  background-image: url('../../assets/images/construction.svg');
  background-size: 65%;
  background-position: bottom right;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 700px) {
  .login {
    .login__user-credentials {
      position: relative;
      right: 30%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .login {
    background-size: 85%;
  }
}
